/* 6.3 Footer ======================================================= */

.site-footer {
  font-size: 0.9em;

  h4 {
    margin-bottom: $padding-default / 4;
  }

  .logo {
    max-height: 56px;
  }
}
