/* 2.6 blanket ======================================================= */
.blanket {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($blanket-color, $blanket-rgba);
    opacity: 1;
    z-index: 0;
  }

  [class^="container"],
  > .content {
    position: relative;
    z-index: 1;
  }

  @each $c, $color in $theme-colors {
    &.blanket-#{$c} {
      &:after {
        background-color: rgba($color, $blanket-rgba);
      }

      @for $a from 1 through 9 {
        $transparency: $a * 0.1;

        &.alpha-#{$a} {
          &:after {
            background-color: rgba($color, $transparency);
          }
        }
      }
    }
  }
}

.inner-blanket {
  position: absolute;
  top: -100px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;

  // could be placed on blanket file, with gradient css class.
  &.gradient {
    background-image: linear-gradient(
      -48deg,
      $inner-blanket-gradient-bg-start 25%,
      $inner-blanket-gradient-bg-end 100%
    );
  }
}
