.input-group-register {
  position: relative;

  label {
    position: absolute;
    bottom: -4px;
    margin-bottom: 0;
  }

  .form-control {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  .btn {
    @include center(y);
    right: 5px;
  }

  &-big {
    .form-control {
      padding: 2.5rem;
    }

    .btn {
      right: 15px;
    }
  }
}
