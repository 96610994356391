/* ==========================================================================
    [6. SECTIONS]
========================================================================== */

@import "sections/app-safety";
@import "sections/advanced-automation-solution";
@import "sections/blog";
@import "sections/built-tech";
@import "sections/bubbled-icons";
@import "sections/extending-core";
@import "sections/focus-on-success";
@import "sections/get-started";
@import "sections/how-it-works";
@import "sections/latest-projects";
@import "sections/sections";
@import "sections/shape-testimonials";
@import "sections/features-carousel";
@import "sections/testimonials";
@import "sections/trending-design";
@import "sections/path-success";
@import "sections/perspective-mockups";
@import "sections/screenshots";
@import "sections/footer";
@import "sections/why-us";
@import "sections/why-people-love-us";
@import "sections/why-choose-us";
@import "sections/slider-testimonials";
