.strong-top-bordered-card {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba($color-darker, 0.3),
    0 -6px 16px -6px rgba($color-darker, 0.025);

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 8px;
    background-color: $color-primary;
    border-top-right-radius: $card-border-radius;
    border-top-left-radius: $card-border-radius;
  }
}

.tool-description-card {
  max-width: 80%;
  position: absolute;
  bottom: -8rem;
  left: 10%;
  z-index: 1;

  @include media-breakpoint-up($grid-float-breakpoint) {
    max-width: 60%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 40%;
  }
}

.swiper-pagination {
  &.highlight-active {
    .swiper-pagination-bullet {
      will-change: width;
      transition: width 0.3s ease-in-out;
    }

    .swiper-pagination-bullet-active {
      width: 18px;
      border-radius: 4px;
    }
  }
}
