/* ==========================================================================
    [8. PAGE HEADING]
========================================================================== */

@import "heading/heading";
@import "heading/integrations";
@import "heading/mobile";
@import "heading/particle";
@import "heading/shapes";
@import "heading/stripes";
@import "heading/social-media";
//@import "heading/smart-business";
@import "heading/automate-social";
@import "heading/app-landing";
@import "heading/app-landing-2";

@import "heading/saas";
