.latest-projects {
  .project-detail {
    position: relative;
    overflow: hidden;
    backface-visibility: hidden;

    img {
      max-width: 100%;
      transition: transform 2s ease;
    }

    .description {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
      transform: translate(0, 100%);
      transition: transform 0.3s ease-in-out;
    }

    &.blanket:after {
      transition: opacity 1.5s ease-out;
    }

    &:hover {
      &.blanket {
        &:after {
          opacity: 0;
        }
      }

      img {
        transform: scale(1.3) rotate(10deg);
      }

      .description {
        opacity: 0.9;
        transform: translate(0, 0);
      }
    }
  }
}
