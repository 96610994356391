/* 7.2 Pricing heading ======================================================= */

.pricing.heading {
  .pricing-plan {
    padding: 0;

    .pricing-details {
      padding: 15px;
    }
  }
}
