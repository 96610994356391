// Some re-estyling for Bootstrap default custom forms

.custom-switch {
  height: $custom-switch-height;
  min-height: $custom-switch-height;

  .custom-control-label {
    &::before {
      top: (($font-size-base * $line-height-base - $custom-switch-height) / 2);
      height: $custom-switch-height;
      border-radius: $custom-switch-height / 2;
    }
    &::after {
      top: (
        ($font-size-base * $line-height-base - $custom-switch-height) / 2 +
          $custom-switch-spacing
      );
      left: $custom-switch-spacing - $custom-control-gutter -
        $custom-switch-width;
      //background-color: $custom-switch-indicator-bg;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      //background-color: $custom-switch-indicator-active-bg;
      transform: translateX(
        $custom-switch-width - $custom-switch-spacing * 2 -
          $custom-switch-indicator-size
      );
    }
  }
}
