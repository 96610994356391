.built-tech {
  .shape {
    position: absolute;

    &-circle {
      top: 0;
      left: 0;

      div {
        transform: translate(-50%, -10%);
        @include circle-variant(400px, $ts-circle-bg-color);
      }
    }
  }

  .mockup {
    max-width: 100px;
  }
}
