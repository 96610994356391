/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Popup modals styles
// Needs to be globally imported according to https://biig-io.github.io/ngx-smart-modal/#/start
@import "~ngx-smart-modal/ngx-smart-modal";

// Theme customization
@import "theme/theme";
